<template>
	<cam-box class="member-social" flex="space-between center">
		<div class="social" v-if="member.facebook_account">
			<a :href="`https://facebook.com/${member.facebook_account}`" target="_blank" title="Facebook">
				<i class="lni lni-facebook"></i>
			</a>
		</div>
		<div class="social" v-if="member.twitter_account">
			<a :href="`https://twitter.com/${member.twitter_account}`" target="_blank" title="Twitter">
				<i class="lni lni-twitter"></i>
			</a>
		</div>
		<div class="social" v-if="member.url">
			<a :href="`${member.url}`" target="_blank" title="Website">
				<i class="lni lni-website"></i>
			</a>
		</div>
		<div class="social" v-if="member.rss_url">
			<a :href="`${member.rss_url}`" target="_blank" title="RSS Feed">
				<i class="lni lni-rss-feed"></i>
			</a>
		</div>
	</cam-box>
</template>

<script lang="ts">
export default {
	name: 'member-social',
	props: {
		member: {type: Object, required: true},
	},
	setup() {
		return {

		}
	},
}
</script>

<style lang="stylus" scoped>
.member-social {
	.social {
		padding: 12px
		i.lni {
			font-size: 1.15rem
		}
	}
}
</style>