<template>
	<cam-box flex="center" p="2" class="el-member-viewer">
		<el-member v-if="member?.member_id" :member="member" :metrics="metrics"
			@loaded="trackInterest"
			@favorite="toggleFavorite"/>
	</cam-box>
</template>

<script lang="ts">
import ElMember from './el-member.vue'
import useEllisContext from 'src/useEllisContext'
import { State, stored } from '../../vuex'
import { Member } from '../../vuex/member.module'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
	name: 'el-member-viewer',
	components: {ElMember},
	props: {
		memberId: {type: String, required: true},
	},
	setup(p: {memberId: string}) {
		const {api, profile} = useEllisContext()
		const store = useStore()

		const member = stored<Member, State>(({getters}) => getters['member/member'](p.memberId),
			({commit}) => api['member/get'](p.memberId).then((member) => commit('member/save', member)))

		async function favorite(member_id: string) {
			const res = await api['member/favorite:POST'](member_id)
			res.status === 201 && store.commit('member/favorite', member_id)
		}
		
		async function unfavorite(member_id: string) {
			const res = await api['member/favorite:DELETE'](member_id)
			res.status === 204 && store.commit('member/unfavorite', member_id)
		}

		const metrics = computed(() => store.getters['member/metrics'](p.memberId))

		function trackInterest(e) {
			console.info(`TODO: track interest`)
		}

		return {
			profile,
			metrics,
			member,
			toggleFavorite: ({id, value}) => !value ? favorite(id) : unfavorite(id),
			trackInterest,
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'
</style>