<template>
	<cam-box flex="center flex-start" dir="column" class="el-member">
		<cam-box class="header" flex="space-between center">
			<div>
				<h1 class="m0">{{member.first_name}} {{member.last_name}}</h1>
				<h4>{{roleTitle}}</h4>
			</div>
			<cam-box flex="flex-end center" class="actions">
				<i title="favorite" :class="['lni favorite', `lni-star${metrics.favorite ? '-filled' : ''}`]"
					@click="() => $emit('favorite', {id: member.member_id, value: metrics.favorite})"/>
				<i title="share" :class="['lni', `lni-link`]"
					@click="() => $emit('share', member.member_id)"/>
			</cam-box>
		</cam-box>

		<cam-box class="stats" flex="center flex-start" m="2 0">
			<el-member-chip size="8rem" thumbnail :id="member.id">
				<member-social :member="member"/>
			</el-member-chip>
			<el-vote-stats :roles="member.roles"/>
		</cam-box>

		<hr>

		<h3>Committees</h3>
		<cam-box flex="flex-start center" class="committees">
			<cam-box v-for="c in member.roles[0].committees" :key="c.name">
				<router-link :to="`/${chamber}/committees/${c.code}`">
					<div class="committee-list-item">
						<h6 class="center">{{c.name}}</h6>
						<span>{{c.title}}</span>
					</div>
				</router-link>
			</cam-box>
		</cam-box>

		<br><br>
		<h3>Latest Votes</h3>
		<cam-box class="voting-record" flex="center">
			<el-voting-record :rep-id="member.id"/>
		</cam-box>
		<br><br>
	</cam-box>
</template>

<script lang="ts">
import {computed} from 'vue'
import {Member} from 'domain/schema/Member.model'
import ElMemberChip from './el-member-chip.vue'
import ElVotingRecord from './el-voting-record.vue'
import ElVoteStats from './el-vote-stats.vue'
import MemberSocial from './member-social.vue'

export default {
	name: 'el-member',
	components: {ElMemberChip, ElVotingRecord, ElVoteStats, MemberSocial},
	props: {
		member: {type: Object, required: true},
		metrics: {type: Object, default: () => ({})},
	},
	emits: ['loaded', 'favorite'],
	setup(p: {member: Member}) {
		const roleTitle = computed(() => p.member.roles[0]?.title)
		const chamber = computed(() => p.member?.roles[0]?.chamber?.toLowerCase())
		return {
			roleTitle,
			chamber,
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.el-member {
	wh(100%, auto)
	max-width: 50rem
	& > .header {
		width: 100%
		.actions {
			i.lni {
				padding: 8px 12px
				&.favorite {
					color: #EEAF1B
					&.lni-star {
						color: #777
					}
				}
			}
		}
	}
	.member-chip {
		margin-right: 12rem
	}
	.stats {
		width: 100%
		flex-wrap: wrap
		& > *:not(:last-child) {
			margin-right: 3rem
		}
	}
	.committees {

		margin: 0
		flex-wrap: wrap
	}
	.committee-list-item {
		font-size: 1.3rem
		wh(200px)
		padding: 6px
		flexXY(center)
		neu(white, 2)
		background: lighten($c-bg, 20%)
		border-radius: 4px
		flex-direction: column
		h6 {
			display: inline-block
		}
		span {
			margin-top: 12px
			color: #ddd
		}
	}

	.voting-record {
		width: 100%
	}
}
</style>