<template>
	<cam-box v-if="role.total_votes == null" flex="center" dir="column" class="el-vote-stats">
		<span>Insufficient stats in current congress ({{CURRENT_CONGRESS}})</span>
	</cam-box>
	<cam-box v-else flex="space-between flex-start" class="el-vote-stats">
		<cam-box flex="center" dir="column">
			<h3 class="m0">{{role.total_votes}}</h3>
			<label>Total Votes</label>
		</cam-box>
		
		<cam-box flex="center" dir="column"> 
			<h3 class="m0">{{role.missed_votes}}</h3>
			<label>Missed Votes</label>
		</cam-box>
		
		<cam-box flex="center" dir="column"> 
			<h3 class="m0">{{role.votes_with_party_pct}}%</h3>
			<label>Votes With Own Party</label>
		</cam-box>
		
		<cam-box flex="center" dir="column"> 
			<h3 class="m0">{{role.votes_against_party_pct}}%</h3>
			<label>Votes Against Own Party</label>
		</cam-box>
	</cam-box>
</template>

<script lang="ts">
import {CURRENT_CONGRESS} from 'domain/Constants'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'el-vote-stats',
	props: {
		roles: {type: Array, required: true},
	},
	setup(p: {roles: object[]}) {
		const store = useStore()
		const congress = computed(() => store.state.prefs.congress || CURRENT_CONGRESS)
		const role = computed(() => p.roles.find((r: any) => r.congress === congress.value.toString()))
		return {
			CURRENT_CONGRESS,
			role,
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.el-vote-stats {
	flex: 1
}
</style>