
import {computed} from 'vue'
import {Member} from 'domain/schema/Member.model'
import ElMemberChip from './el-member-chip.vue'
import ElVotingRecord from './el-voting-record.vue'
import ElVoteStats from './el-vote-stats.vue'
import MemberSocial from './member-social.vue'

export default {
	name: 'el-member',
	components: {ElMemberChip, ElVotingRecord, ElVoteStats, MemberSocial},
	props: {
		member: {type: Object, required: true},
		metrics: {type: Object, default: () => ({})},
	},
	emits: ['loaded', 'favorite'],
	setup(p: {member: Member}) {
		const roleTitle = computed(() => p.member.roles[0]?.title)
		const chamber = computed(() => p.member?.roles[0]?.chamber?.toLowerCase())
		return {
			roleTitle,
			chamber,
		}
	}
}
