<template>
	<div class="vote-history">
		<div v-if="votes?.length > 0" class="chart">
			<div class="legend">
				<div class="item"><div class="indicator bg-c-yea"/><span>Yea</span></div>
				<div class="item"><div class="indicator bg-c-nay"/><span>Nay</span></div>
				<div class="item"><div class="indicator bg-c-abstain"/><span>Abstain</span></div>
			</div>
			<div class="chart-guides"/>
			<div class="chart-guides-horizontal"
				:style="{
					'top': `${marginTop(votes[0]) + votes[0].total.yes * amplitude + 16}px`,
				}"/>
			<div v-for="v in votes" :key="v.roll_call"
				@click="() => selectVote(v)"
				@mouseover="() => hoverVote(v)"
				@mouseleave="() => unhoverVote(v)">
				<vote-history-bar :vote="v" :amplitude="amplitude" :class="{selected: v.roll_call === selectedVote?.roll_call}"/>
			</div>
		</div>
		<div class="vote-summary">
			<div v-if="selectedVote || hoveredVote" class="title">
				<div v-if="hoveredVote">
					<h4 class="center">{{hoveredVote.type}}</h4>
					<div class="name" v-if="['bill', 'amendment'].includes(hoveredVote.type)">
						<router-link :to="`/bills/${hoveredVote.bill.bill_id}`">
							<h5 class="m0"><b>{{hoveredVote.bill.number}}</b></h5>
						</router-link>
						<i class="lni lni-share" @click="() => {}"/>
					</div>
					<p>{{hoveredVote.question}} {{hoveredVote.description}}</p>
					<blockquote><b>Result:</b> {{hoveredVote.result}}</blockquote>
				</div>
				<div v-else>
					<h4 class="center">{{selectedVote.type}}</h4>
					<div class="name" v-if="['bill', 'amendment'].includes(selectedVote.type)">
						<router-link :to="`/bills/${selectedVote.bill.bill_id}`">
							<h5 class="m0"><b>{{selectedVote.bill.number}}</b></h5>
						</router-link>
						<i class="lni lni-share" @click="() => {}"/>
					</div>
					<p>{{selectedVote.question}} {{selectedVote.description}}</p>
					<blockquote><b>Result:</b> {{selectedVote.result}}</blockquote>
				</div>
			</div>
			<div v-else class="instructions">
				Select or hover over a vote for details.
			</div>
			<br><br>
			<hr>
		</div>
	</div>
</template>

<script>
import {inject, ref, computed} from 'vue'
import VoteHistoryBar from './vote-history-bar.vue'
import {useStore} from 'vuex'

export default {
	name: 'vote-history',
	components: {VoteHistoryBar},
	props: {
		votes: {type: Array, required: true},
	},
	emits: ['selected', 'hovered'],
	setup(p, {emit}) {
		const store = useStore()

		const selectedVote = ref(null)
		const hoveredVote = ref(null)
		const scale = ref(0.6)

		const firstVoteTotals = computed(() => p.votes[0].total.no + p.votes[0].total.yes + p.votes[0].total.not_voting)
		const amplitude = computed(() => (120 / firstVoteTotals.value) * scale.value)

		const voteType = (vote, billId) => {
			if(vote.amendment.number) return 'amendment'
			if (billId?.match(/^pn(\d+)/i)) return 'nomination'
			if (billId?.match(/^(hr|hjr|s)/i)) return 'bill'
		}
		const selectVote = (vote) => {
			selectedVote.value = {...vote, type: voteType(vote, vote.bill.bill_id)}
			emit('selected', vote)
		}
		const hoverVote = (vote) => {
			hoveredVote.value = {...vote, type: voteType(vote, vote.bill.bill_id)}
			emit('hovered', vote)
		}
		const unhoverVote = () => hoveredVote.value = null

		const marginTop = (vote) => vote.total.no * amplitude.value

		return {
			selectedVote,
			hoveredVote,
			scale,

			firstVoteTotals,
			amplitude,

			selectVote,
			hoverVote,
			unhoverVote,
			marginTop,
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.chart {
	justify-content: center
	position: relative
	display: flex
	padding: 16px
	.chart-guides {
		width: 2px
		background: #aaa
	}
	.vote-history-bar {
		box-sizing content-box
		width: 10px
		border: 2px solid transparent
		margin: 0 2px
		padding: 8px 4px
		border-radius: 4px
		background: transparent

		cursor: pointer
		&:hover {
			background: lighten(#66bbff, 80%)
			border: 2px solid #66bbff
		}
		&.selected {
			background: lighten(#66bbff, 80%)
			border: 2px solid #66bbff
			boxShadow(20, 50, 240, 0.4, true)
		}
	}

	.legend {
		display: flex
		flex-direction column
		justify-content: center
		h5 {
			margin: 8px 0
			font-size: 1.2em
		}
		padding: 16px
		.item {
			flexXY(flex-start, center)
			.indicator {
				wh(16px)
				border: 2px solid #aaa
				border-radius: 4px
				margin: 6px 8px 6px 0
				&.bg-c-yea {
					background-color: $c-yea
				}
				&.bg-c-nay {
					background-color: $c-nay
				}
				&.bg-c-abstain {
					background-color: $c-abstain
				}
			}
			span {
				font_label()
				font-size: 0.8em
				letter-spacing: 0.05em
				color: #888
			}
		}
	}
}

.vote-summary {
	.instructions {
		min-height: 160px
		flexXY(center)
		color: #888
	}
}

.title {
	.name {
		flexXY(flex-start, center)
		& > * {
			padding-right: 12px
			i.lni {
				padding-bottom: 20px
			}
		}
	}
}
</style>

