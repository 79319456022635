
export default {
	name: 'member-social',
	props: {
		member: {type: Object, required: true},
	},
	setup() {
		return {

		}
	},
}
