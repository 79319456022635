<template>
	<div class="vote-count">
		<div class="stats">
			<h3>Total</h3>
			<div class="vote">
				<span title="yea" class="tally yea">{{vote.total.yes}}</span> /
				<span title="nay" class="tally nay">{{vote.total.no}}</span> /
				<span title="abstain" class="tally abstain">{{vote.total.not_voting}}</span>
			</div>
			<div class="vote small">
				<label class="party">Democrat</label>
				<span title="yea" class="tally yea">{{vote.democratic.yes}}</span> /
				<span title="nay" class="tally nay">{{vote.democratic.no}}</span> /
				<span title="abstain" class="tally abstain">{{vote.democratic.not_voting}}</span>
			</div>
			<div class="vote small">
				<label class="party">Republican</label>
				<span title="yea" class="tally yea">{{vote.republican.yes}}</span> /
				<span title="nay" class="tally nay">{{vote.republican.no}}</span> /
				<span title="abstain" class="tally abstain">{{vote.republican.not_voting}}</span>
			</div>
			<div class="vote small">
				<label class="party">Independent</label>
				<span title="yea" class="tally yea">{{vote.independent.yes}}</span> /
				<span title="nay" class="tally nay">{{vote.independent.no}}</span> /
				<span title="abstain" class="tally abstain">{{vote.independent.not_voting}}</span>
			</div>
		</div>
		<div class="chart">
			<div
				class="positions"
				:style="{'grid-template-rows': `repeat(${chamber === 'SENATE' ? 8 : 24}, 1fr)`}">
				<seat-vote-position v-for="p in sortedPositions"
					:position="p"
					:highlight="!!repId ? p.member_id === repId : false"
					:key="p.member_id"/>
			</div>
		</div>
	</div>
</template>

<script>
import SeatVotePosition from './seat-vote-position.vue'

const alphaSortBy = (p) => (a, b) => {
	if (a[p] < b[p]) return -1
	if (a[p] > b[p]) return 1
	return 0
}

export default {
	name: 'vote-count',
	components: {SeatVotePosition},
	props: {
		vote: {type: Object, required: true},
		repId: {type: String, default: null},
	},
	data: () => ({
		sortProperty: 'party',
	}),
	computed: {
		sortedPositions() {
			const sorted = this.vote.positions
			return sorted.sort(alphaSortBy(this.sortProperty))
		},
		chamber() {
			return this.vote.positions.length > 150 ? 'HOUSE' : 'SENATE'
		},
	},
}
</script>

<style lang="stylus" scoped>
@require '~style/variables.styl'

.vote-count {
	display: flex
	justify-content center
}

.stats {
	h3 {
		margin-top: 12px
		margin-bottom: 4px
	}
}

.positions {
	margin: 24px
	display: grid
	grid-auto-flow: column
}

.vote {
	color: #888
	font-size: 2.4em
	&.small {
		font-size: 1.4em
	}
	label.party {
		display: inline-block
		font-size: 0.8em
		width: 100px
		margin-right: 12px
	}
	.yea.tally {
		color: $c-yea
	}
	.nay.tally {
		color: $c-nay
	}
	.abstain.tally {
		color: darken($c-abstain, 20%)
	}
}
</style>

