
import {CURRENT_CONGRESS} from 'domain/Constants'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'el-vote-stats',
	props: {
		roles: {type: Array, required: true},
	},
	setup(p: {roles: object[]}) {
		const store = useStore()
		const congress = computed(() => store.state.prefs.congress || CURRENT_CONGRESS)
		const role = computed(() => p.roles.find((r: any) => r.congress === congress.value.toString()))
		return {
			CURRENT_CONGRESS,
			role,
		}
	},
}
