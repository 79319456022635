<template>
	<div class="vote-history-bar" :key="vote.roll_call"
		:style="{
			'height': `${height(vote)}px`,
			'margin-top': `${marginTop(vote)}px`
		}">
		<div class="vote-yea"
			:style="{
				'height': `${vote.total.yes * amplitude}px`
			}"/>
		<div class="vote-abstain"
			:style="{
				'height': `${vote.total.not_voting * amplitude}px`
			}"/>
		<div class="vote-nay"
			:style="{
				'height': `${vote.total.no * amplitude}px`
			}"/>
	</div>
</template>

<script>
export default {
	name: 'vote-history-bar',
	data: () => ({
		hover: false,
	}),
	props: {
		vote: {
			type: Object,
			required: true,
		},
		amplitude: {
			type: Number,
			default: 100,
		},
	},
	methods: {
		height() {
			const v = this.vote
			return (v.total.yes + v.total.no + v.total.not_voting) * this.amplitude
		},
		marginTop() {
			const v = this.vote
			return v.total.no * this.amplitude
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.vote-history-bar {
	box-sizing content-box
	width: 10px
	border: 2px solid transparent
	margin: 0 2px
	padding: 8px 4px
	background: transparent
	cursor: pointer

	.vote-yea {
		background-color: $c-yea;
	}
	.vote-nay {
		background-color: $c-nay;
	}
	.vote-abstain {
		background-color: $c-abstain;
	}
}
</style>
