
import {computed, inject, ref, watch} from 'vue'
import { computedAsync } from 'src/util/computed'
import { EllisApi } from 'src/services/EllisService'
import {CURRENT_CONGRESS} from 'domain/Constants'
import VoteHistory from 'src/components/vote/vote-history.vue'
import {voteKey} from 'src/util/vote'
import VoteCount from 'src/components/vote/vote-count.vue'
import { useStore } from 'vuex'

export default {
	name: 'el-voting-record',
	components: {VoteHistory, VoteCount},
	props: {
		repId: {type: String, required: true},
	},
	setup(props) {
		const api: EllisApi = inject('api')
		const store = useStore()

		const vote = ref(null)
		const getVotes = () => api.proPub(`members/${props.repId}/votes`)
			.then(({results}) => results[0].votes)

		const selected = ref(null)

		watch(selected, async (v) => {
			const vote = await api.proPub(v.vote_uri).then(({results}) => results.votes.vote)
			store.commit('vote/saveVoteFromUrl', vote)
		})

		return {
			vote: computed(() => selected.value && store.getters['vote/read'](voteKey(selected.value))),
			votes: computedAsync(getVotes, []),
			selected,
		}
	}
}
