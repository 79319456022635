<template>
	<div
		:class="['seat-vote-position', {
			abstain: vote === 'NOT VOTING',
			nay: vote === 'NO',
			yea: vote === 'YES',
			speaker: vote === 'SPEAKER',
		}]">
		<div v-if="highlight" class="rep">
			<i class="lni lni-user"
				:class="`c-party_${position.party}`"/>
		</div>
		<div v-else :class="`rep bg-party_${position.party}`"/>
		<div class="member-info card">
			<div class="name">
				<h3>{{position.name}}</h3>
			</div>
			{{position.member_id}}
			{{position.state}}
		</div>
	</div>
</template>

<script>
export default {
	name: 'seat-vote-position',
	props: {
		position: {type: Object, required: true},
		highlight: {type: Boolean, required: false},
	},
	computed: {
		vote() {
			return this.position.vote_position ? this.position.vote_position.toUpperCase() : 'NON_VOTE'
		},
	},
	methods: {
		showMember() {
			console.log(this.position)
		},
		hideMember() {

		},
	},
}
</script>


<style lang="stylus" scoped>
@require '~style/mixins.styl'
@require '~style/variables.styl'

$grid-dim = 20px
$rep-dim = 12px

.seat-vote-position {
	position relative
	margin: 0 auto
	flexXY(center, center)
	wh($grid-dim + 4px)
	&::after {
		content: ''
		position: absolute
		right: 0
		top: 0
		circle(10px)
		border: 1px solid white
	}
	&.yea::after {
		background: darken($c-yea, 20%)
	}
	&.nay::after {
		background: $c-nay
	}
	&.abstain::after {
		background: $c-abstain
		border: 1px solid #999
	}
	&.speaker::after {
		content: none
	}
	.rep {
		border-radius: 3px
		wh($rep-dim)
		flexXY(center, center)
		&.party_I {

			border: 1px solid #bbb
		}
		i.lni {
			font-size: 1.4rem
			color: #333
		}
	}
	.member-info {
		absPos(100%, auto, auto, 100%)
		background: linear-gradient(90deg, rgba(250, 250, 250, 0.85), white)
		z-index: 1
		display: none
		padding: 4px
	}

	&:hover {
		.member-info {
			display: flex
		}
	}
}
</style>

